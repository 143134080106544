import React from 'react';
import { Layout, Row, Col, Button, Typography, Card } from 'antd';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import CalendarComponent from '../calendar/CalendarComponent';
import MessageList from '../calendar/MessageList'; // Import the MessageList component
import BarCodeComponent from '../forms/BarCodeComponent';
import RačuniTable from '../ux/RačuniTable';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

const PrivateView: React.FC = () => {
  const { signOut, user } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut();
      // Redirect to the login page after logout
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <Content style={{ background: 'linear-gradient(to bottom, #6dbb63, #70ba68, #74b86c, #78b671, #7cb476)', minHeight: '100vh', padding: '20px' }}>
      {/* Logout Button */}
      <Button type="text" onClick={handleLogout} icon={<LogoutOutlined />} style={{ position: 'absolute', top: 10, right: 10 }} />

      <Row justify="center" align="top" style={{ minHeight: '100vh', marginTop: 30, marginBottom: 30 }} gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <Card
            headStyle={{ border: 'none', borderRadius: '8px 8px 0 0', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            bodyStyle={{ padding: '20px', borderRadius: '0 0 8px 8px', backgroundColor: 'transparent', position: 'relative', marginBottom: '20px' }}
          >
            <Title level={2} style={{ marginBottom: '20px' }}>Admin Dashboard</Title>
            <Paragraph style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '10px', maxWidth: '480px' }}>
              Explore the <span style={{ color: '#74b86c' }}>Admin Dashboard</span> to manage your contacts effortlessly. 
              Easily <span style={{ color: '#70ba68' }}>view</span>, <span style={{ color: '#70ba68' }}>edit</span>, and <span style={{ color: '#70ba68' }}>check</span> entries for a streamlined organizational experience.
            </Paragraph>

            {/* Include the CalendarComponent */}
            <CalendarComponent />
          </Card>
          <Card
            style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, marginTop: 20}}
            headStyle={{ border: 'none', borderRadius: '8px 8px 0 0', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            bodyStyle={{ padding: '20px', borderRadius: '0 0 8px 8px', backgroundColor: 'transparent', position: 'relative', marginBottom: '20px', }}
          >
            <Title level={2} style={{ marginBottom: '20px' }}>Generated bills list</Title>
            <Paragraph style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '10px', maxWidth: '480px' }}>
              Explore your <span style={{ color: '#74b86c' }}>Generated</span> bills in one place. Here, you can  <span style={{ color: '#74b86c' }}>view</span>, all your generated barcode bills. 
            </Paragraph>
            <RačuniTable />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={10}>
          <Card
            style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}
            headStyle={{ border: 'none', borderRadius: '8px 8px 0 0', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            bodyStyle={{ padding: '20px', borderRadius: '0 0 8px 8px', backgroundColor: 'transparent', position: 'relative', marginBottom: '20px', }}
          >
            <Title level={2} style={{ marginBottom: '20px' }}>Messages List</Title>
            <Paragraph style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '10px', maxWidth: '480px' }}>
              Explore your <span style={{ color: '#74b86c' }}>messages</span> effortlessly in one place. Here, you can  <span style={{ color: '#74b86c' }}>view</span>, <span style={{ color: '#74b86c' }}>check</span> and <span style={{ color: '#74b86c' }}>manage</span> all your contacts' messages. 
            </Paragraph>

            {/* Include the MessageList component */}
            <MessageList />
          </Card>
          <Card
            style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, marginTop: 20}}
            headStyle={{ border: 'none', borderRadius: '8px 8px 0 0', backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
            bodyStyle={{ padding: '20px', borderRadius: '0 0 8px 8px', backgroundColor: 'transparent', position: 'relative', marginBottom: '20px', }}
          >
            <Title level={2} style={{ marginBottom: '20px' }}>Barcode generator</Title>
            <Paragraph style={{ fontSize: '14px', lineHeight: '1.5', marginBottom: '10px', maxWidth: '480px' }}>
              Generate barcode <span style={{ color: '#74b86c' }}>payment </span> effortlessly in one place. Here, you can  <span style={{ color: '#74b86c' }}>create</span> all your barcodes. 
            </Paragraph>

            {/* Include the MessageList component */}
            <BarCodeComponent />
          </Card>
        </Col>
      </Row>
    </Content>
  );
};

export default PrivateView;
