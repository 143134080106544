import React from 'react';
import { Layout, Row, Col } from 'antd';
import LoginForm from '../forms/LoginForm';

const { Content } = Layout;

const LoginView: React.FC = () => {
  const localImageUrl = 'https://floring.hr/gallery1.jpg'; // Replace with the path to your local image

  const handleLogin = (values: any) => {
    // Handle login logic here
    console.log('Received values:', values);
  };

  const backgroundStyle = {
    backgroundImage: `url("${localImageUrl}")`,
    backgroundSize: 'cover',
    minHeight: '100vh',
    transition: 'background 1s ease',
  };

  return (
    <Content style={backgroundStyle}>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} sm={18} md={12} lg={8} xl={6}>
          <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', borderRadius: '8px' }}>
            <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Login</h2>
            <LoginForm onFinish={handleLogin} />
          </div>
        </Col>
      </Row>
    </Content>
  );
};

export default LoginView;
