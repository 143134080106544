import React, { useEffect, useState } from 'react';
import { Table, Input, Select, Row, Col, Button, Modal } from 'antd';
import { db } from '../../firebaseConfig';
import { collection, onSnapshot, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { DeleteOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CloseSquareOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Option } = Select;
const { confirm } = Modal;

const RačuniTable = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'racuni'), (querySnapshot) => {
      const items = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        key: doc.id
      }));
      setData(items);
    });

    return () => unsubscribe();
  }, []);

  const handleDelete = (id: any) => {
    confirm({
      title: 'Are you sure delete this item?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone',
      async onOk() {
        setLoading(true);
        try {
          await deleteDoc(doc(db, 'racuni', id));
          setLoading(false);
        } catch (error) {
          console.error('Error deleting document:', error);
          setLoading(false);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handlePayed = (id: string) => {
    confirm({
      title: 'Are you sure this item is paid?',
      icon: <ExclamationCircleOutlined />,
      content: 'Confirming this will mark the bill as paid and this action cannot be undone.',
      async onOk() {
        setLoading(true);
        try {
          const docRef = doc(db, 'racuni', id);
          await updateDoc(docRef, {
            isPayed: true // Set isPayed to true
          });
          setLoading(false);
        } catch (error) {
          console.error('Error updating document:', error);
          setLoading(false);
        }
      },
      onCancel() {
        console.log('Mark as paid cancelled.');
      },
    });
  };


  const columns = [
    { title: 'Header', dataIndex: 'header', key: 'header' },
    { title: 'Bill payed', dataIndex: 'isPayed', key: 'isPayed', render: (isPayed: boolean) => isPayed ? <CheckCircleOutlined style={{fontSize: 24, color: 'green'}}/> : <CloseSquareOutlined style={{fontSize: 24, color: 'red'}} /> },
    { title: 'Amount', dataIndex: 'amount', key: 'amount' },
    { title: 'Payer Name', dataIndex: 'payerName', key: 'payerName' },
    { title: 'Payer Address', dataIndex: 'payerAddress', key: 'payerAddress' },
    { title: 'Payer City', dataIndex: 'payerCity', key: 'payerCity' },
    { title: 'Recipient Name', dataIndex: 'recipientName', key: 'recipientName' },
    { title: 'Recipient Address', dataIndex: 'recipientAddress', key: 'recipientAddress' },
    { title: 'Recipient City', dataIndex: 'recipientCity', key: 'recipientCity' },
    { title: 'IBAN', dataIndex: 'iban', key: 'iban' },
    { title: 'Model', dataIndex: 'model', key: 'model' },
    { title: 'Reference Number', dataIndex: 'referenceNumber', key: 'referenceNumber' },
    { title: 'Purpose Code', dataIndex: 'purposeCode', key: 'purposeCode' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    { title: 'Barcode Image', dataIndex: 'barcodeImageRef', key: 'barcodeImageRef', width:120,
    render: (text: string) => <img src={text} alt="Barcode" style={{ width: '100px' }} />
    },
    { title: 'Actions', key: 'actions', render: (_: any, record: any) => 
    <>
      <Button loading={loading} onClick={() => handleDelete(record.key)} type="text" icon={<DeleteOutlined style={{color:"red", fontSize: 24}}/>} />
      <Button loading={loading} onClick={() => handlePayed(record.key)} type="text" icon={<CheckCircleOutlined style={{color: !record.isPayed ? "green" : "lightgrey", fontSize: 24}}/>} disabled={record.isPayed} />
    </>
    }
  ];

  return (
    <>
            <Table dataSource={data} columns={columns} rowKey="id" scroll={{x: 1440, y: 450 }}/>

    </>
  );
};

export default RačuniTable;
