// src/context/messagesContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, query, getDocs, updateDoc, doc, setDoc, addDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // Import uuid to generate a unique ID

interface Message {
  id: string;
  name: string;
  message: string;
  email: string;
  phone: string;
  // Add other message properties as needed
  read: boolean;
  datetime: {
    toDate: () => Date;
  };
}

interface MessagesContextProps {
  messages: Message[];
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  updateReadStatus: (id: string, read: boolean) => Promise<void>;
  saveNewMessage: (newMessage: Omit<Message, 'id' | 'datetime'>) => Promise<void>;
  fetchMessages: () => Promise<void>;
}

const MessagesContext = createContext<MessagesContextProps | undefined>(undefined);

interface MessagesProviderProps {
  children: ReactNode;
}

export const MessagesProvider: React.FC<MessagesProviderProps> = ({ children }) => {
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messagesQuery = query(collection(db, 'messages'));
        const querySnapshot = await getDocs(messagesQuery);

        const messagesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Message[];

        setMessages(messagesData);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const messagesQuery = query(collection(db, 'messages'));
      const querySnapshot = await getDocs(messagesQuery);

      const messagesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Message[];

      setMessages(messagesData);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const updateReadStatus = async (id: string, read: boolean) => {
    try {
      const messageDocRef = doc(db, 'messages', id);
      await updateDoc(messageDocRef, { read });
      // Optionally, update local state immediately
      setMessages((prevMessages) =>
        prevMessages.map((message) =>
          message.id === id ? { ...message, read } : message
        )
      );
    } catch (error) {
      console.error('Error updating read status:', error);
    }
  };

  const saveNewMessage = async (newMessage: Omit<Message, 'id' | 'datetime'>) => {
    try {
       // Generate a random 16-character long unique ID
      const customId = uuidv4().replace(/-/g, '').slice(0, 16);

      // Create a DocumentReference with the custom ID
      const customDocRef = doc(db, 'messages', customId);

      // Set the document with the provided data
      await setDoc(customDocRef, {
        ...newMessage,
        id: customId, // Add the custom ID to the data
        datetime: new Date(),
      });

      // Update local state with the new message, including the custom ID
      setMessages((prevMessages) => [
        ...prevMessages,
        { id: customId, ...newMessage, read: false, datetime: { toDate: () => new Date() } },
      ]);
    } catch (error) {
      console.error('Error saving new message:', error);
      // Handle error, show an error message, etc.
    }
  };

  const contextValue: MessagesContextProps = {
    messages,
    setMessages,
    updateReadStatus,
    saveNewMessage,
    fetchMessages
  };

  return (
    <MessagesContext.Provider value={contextValue}>{children}</MessagesContext.Provider>
  );
};

export const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
};
