// src/context/modalContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ModalContextProps {
  isModalVisible: boolean;
  openModal: (eventId: string) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

interface ModalProviderProps {
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  const openModal = (eventId: string) => {
    setSelectedEventId(eventId);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedEventId(null);
    setIsModalVisible(false);
  };

  const contextValue: ModalContextProps = {
    isModalVisible,
    openModal,
    closeModal,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
