import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './firebaseConfig'
import { AuthProvider } from './context/authContext';
import { ModalProvider } from './context/modalContext';
import { MessagesProvider } from './context/messagesContext'; // Import the MessagesProvider
import { HelmetProvider } from 'react-helmet-async';
import { MenuProvider } from './context/menuContext';
import AppMeta from './components/ux/AppMeta';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HelmetProvider>
    <MenuProvider>
      <React.StrictMode>
        <AuthProvider>
          <ModalProvider>
            <MessagesProvider>
              <AppMeta />
              <App />
            </MessagesProvider>
          </ModalProvider>
        </AuthProvider>
      </React.StrictMode>
    </MenuProvider>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
