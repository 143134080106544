// src/components/LoginForm.tsx
import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { GoogleOutlined } from '@ant-design/icons';

interface LoginFormProps {
  onFinish: (values: any) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onFinish }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const { signIn, signInWithGoogle } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await signIn(email, password);
      message.success('Login successful');
      navigate('/admin');
      onFinish({ email, password });
    } catch (error) {
      console.error('Login error:', error);
      message.error('Login failed. Please check your email and password.');
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
      message.success('Google login successful');
      navigate('/admin');
    } catch (error) {
      console.error('Google login error:', error);
      message.error('Google login failed. Please try again.');
    }
  };

  return (
    <Form onFinish={handleLogin}>
      {/* <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item> */}
      <Button
        type="primary"
        icon={<GoogleOutlined />} // Add GoogleOutlined as an Ant Design icon
        onClick={handleGoogleLogin}
        style={{
          marginTop: 5,
          width: '100%',
          backgroundColor: '#fff', // Set background color to white
          borderColor: '#4285F4', // Set border color to Google blue
          color: '#4285F4', // Set text color to Google blue
        }}
      >
        Login with Google
      </Button>
    </Form>
  );
};

export default LoginForm;
