import React, { useState } from "react";
import contact from "../../assets/images/contact.svg";
import { useMessages } from '../../context/messagesContext';
import { notification } from 'antd';

export default function GetInTouch() {
  const { saveNewMessage } = useMessages();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    comments: '',
  });

  const [formValid, setFormValid] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Implement your form validation logic here
    const isNameValid = formData.name.trim() !== '';
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email);
    const isPhoneValid = /^\d{9,}$/.test(formData.phone); // Phone must be more than 8 digits
    const isCommentsValid = formData.comments.trim().length > 9; // Comments must be more than 10 characters

    // Set the overall form validity based on individual validations
    const isFormValid = isNameValid && isEmailValid && isPhoneValid && isCommentsValid;

    // Update the form validity state
    setFormValid(isFormValid);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      // Add form validation logic here if needed
      // ...

      // Save the form data using the saveNewMessage function
      await saveNewMessage({
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        message: formData.comments,  // Assuming 'comments' corresponds to the 'message' property in the Message type
        read: false,
      });

      // Reset the form or show a success message
      setFormData({
        name: '',
        email: '',
        phone: '',
        comments: '',
      });

      // Use Ant Design notification for success
      notification.success({
        message: 'Poruka uspješno poslana',
        description: 'Hvala na slanju! Uskoro ćemo vas kontaktirati.',
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error, show an error message, etc.
    }
  };

  return (
    <section className="section" id="contact">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
            <div className="card shadow rounded border-0">
              <div className="card-body py-5">
                <h4 className="card-title">Stupite u kontakt!</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  Molimo ispunite ovaj obrazac i javit ćemo vam se u najkraćem mogućem roku.
                </p>

                <div className="custom-form mt-5">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label fw-normal">
                            Vaše ime <span className="text-danger">*</span>
                          </label>
                          <input
                            name="name"
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Ime :"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="form-label fw-normal">
                            Vaš email <span className="text-danger">*</span>
                          </label>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            className="form-control"
                            placeholder="Email :"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label fw-normal">Telefon</label>
                          <input
                            name="phone"
                            id="phone"
                            className="form-control"
                            placeholder="Telefon :"
                            type="number"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label fw-normal">
                            Vaša poruka <span className="text-danger">*</span>
                          </label>
                          <textarea
                            name="comments"
                            id="comments"
                            rows={4}
                            className="form-control"
                            placeholder="Poruka :"
                            value={formData.comments}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="d-grid">
                          <button
                            type="submit"
                            id="submit"
                            name="send"
                            className="btn btn-primary-border-0 text-white"
                            disabled={!formValid}
                            style={{background: 'linear-gradient(to bottom, #6dbb63, #70ba68, #74b86c, #78b671, #7cb476)'}}
                          >
                            Pošalji poruku
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 order-1 order-md-2">
            <div className="card border-0">
              <div className="card-body p-0">
                <img src={contact} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
