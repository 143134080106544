import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import PublicView from './components/views/Public';
import PrivateView from './components/views/Private';
import LoginView from './components/views/Login';
import { AuthProvider, useAuth } from './context/authContext';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./assets/scss/style.scss"
import "./assets/css/materialdesignicons.min.css"

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <div>
            <section>
              <Routes>
                {/* PublicView is visible to all users */}
                <Route path="/" element={<PublicView />} />

                {/* LoginView is visible to non-logged-in users */}
                <Route path="/login" element={<LoginView />} />

                {/* PrivateView is visible only to logged-in users */}
                <Route
                  path="/admin"
                  element={<PrivateRoute component={<PrivateView />} />}
                />
              </Routes>
            </section>
          </div>
        </Router>
      </AuthProvider>
    </div>
  );
}

// PrivateRoute component to conditionally render a route based on authentication status
const PrivateRoute: React.FC<{ component: React.ReactNode }> = ({ component }) => {
  const { user, loading } = useAuth();

  if (loading) {
    // You might want to display a loading spinner or other loading indicator
    return <div>Loading...</div>;
  }

  // If the user is logged in, render the provided component; otherwise, redirect to the login page
  return user ? <>{component}</> : <Navigate to="/login" />;
};

export default App;
