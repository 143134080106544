import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { clientsData } from "../../data/data";

interface ClientData {
  id: string;
  name: string;
  title: string;
  desc: string;
  image: string;
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3, // Set this to the maximum number of slides you want to display at once
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Clients: React.FC = () => {
  return (
    <div className="row">
    <div className="col-12 mt-4">
        <Slider {...settings}>
            {clientsData.map((item: ClientData, index: number) => (
                <div className="client-testi" key={item.id}>
                    <div className="card border-0 bg-white d-flex flex-column" style={{ height: "100%", minHeight: 480 }}>
                        <div className="card-body p-4 rounded shadow m-2 d-flex flex-column align-items-stretch flex-fill" style={{ minHeight: 320 }}>
                            <div className="d-flex mt-4">
                                <img
                                    src={item.image}
                                    className="avatar avatar-md-sm rounded-circle shadow-md"
                                    alt=""
                                />
                                <div className="flex-1 ms-3">
                                    <h6 className="mb-0">{item.name}</h6>
                                    <small className="text-muted">{item.title}</small>
                                </div>
                            </div>
                            <p className="text-muted fst-italic mb-0 mt-2">{item.desc}</p>
                        </div>
                    </div>
                </div>
            ))}
        </Slider>
    </div>
</div>
  );
};

export default Clients;