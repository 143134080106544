// AppMeta.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMenuContext } from '../../context/menuContext';

const AppMeta: React.FC = () => {
  const { toggleMenu } = useMenuContext();

  const title = 'FLORING Osijek';
  const description =
    'Obiteljska tvrtka koja se bavi hortikulturom više od 30 godina. Proizvodnja travnog busena, ukrasnih biljaka, uređivanje zelenih površina i ugradnja automatskih sustava za navodnjavanje.';
  const contactName = 'Kornelija Škrinjarić';
  const contactName2 = 'Ivan Komadina';
  const appName = 'FLORING';

  return (
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        {/* Open Graph meta tags (Facebook) */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://floring.hr/og.jpg" />
        <meta property="og:url" content="https://floring.hr" />

        {/* Twitter meta tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content="https://floring.hr/og.jpg" />

        {/* Instagram meta tags */}
        <meta property="og:site_name" content={appName} />
        <meta property="og:locale" content="hr_HR" />
        <meta property="og:profile:first_name" content={contactName} />
        <meta property="og:profile:last_name" content={contactName2} />

        {/* Viber meta tags */}
        <meta property="viber:image" content="https://floring.hr/og.jpg" />
        
        {/* Apple Touch Icon */}
        <link rel="apple-touch-icon" sizes="180x180" href="https://floring.hr/apple-touch-icon.png" />

        {/* Favicon */}
        <link rel="icon" type="image/png" sizes="32x32" href="https://floring.hr/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="https://floring.hr/favicon-16x16.png" />
      <link rel="icon" href="https://floring.hr/favicon.ico" />

        {/* Manifest */}
        <link rel="manifest" href="https://floring.hr/site.webmanifest" />

        {/* Mask Icon */}
        <link rel="mask-icon" href="https://floring.hr/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>
  );
};

export default AppMeta;
