import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Link as Link1 } from 'react-scroll';
import logoLight from "../../assets/floring-logo.png";

import {
  TbMailStar,
  AiOutlineShoppingCart,
  AiOutlineDribbble
} from "../../assets/icons/vander";

export default function Footer() {

const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <footer className="bg-footer" style={{ background: 'linear-gradient(to bottom, #6dbb63, #70ba68, #74b86c, #78b671, #7cb476)' }}>
      <div className="py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-8">
              <div className="section-title">
                <div className="d-flex align-items-center">
                  <TbMailStar className="display-4 text-white title-dark" />
                  <div className="flex-1 ms-md-4 ms-3">
                    <h4 className="fw-bold text-light title-dark mb-1">Kontaktirajte nas za pomoć!</h4>
                    <p className="text-white mb-0">Stojimo vam na raspolaganju.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 mt-4 mt-sm-0">
              <div className="text-md-end ms-5 ms-sm-0">
                <Link1 to="contact" activeClass="active" spy={true} smooth={true} duration={500} className="btn btn-primary-border-2 border-white text-white" style={{background: 'linear-gradient(to bottom, #6dbb63, #70ba68, #74b86c, #78b671, #7cb476)'}}>
                  Javite nam se
                </Link1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-py-30 footer-bar">
        <div className="container text-center">
          <div className="row">
            <div className="col">
              <div className="text-center">
                <p className="mb-0 text-white">
                Floring d.o.o. | Ferde Livadića 12, 31000 Osijek | OIB: 51396122452 | MBS: 03988716 | IBAN HR0523400091100233115 PBZ | Temeljni kapital 242.882,74 Eur uplaćen u cijelosti 
                </p>
                <p className="mb-0 text-white">
                  Floring obrt za proizvodnju travnog busena vl. Ivan Komadina | Bosutska 9. 31326 Uglješ | OIB 81650616434 | IBAN HR9223400091160190159 PBZ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}