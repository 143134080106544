// src/components/MessageList.tsx
import React, { useEffect } from 'react';
import { useMessages } from '../../context/messagesContext';
import { Tabs, Card, Tag, Button } from 'antd';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

interface Message {
  id: string;
  name: string;
  message: string;
  email: string;
  phone: string;
  // Add other message properties as needed
  read: boolean;
  datetime: {
    toDate: () => Date;
  };
}

const MessageCard: React.FC<{ message: Message; markAsRead: () => void }> = ({
  message,
  markAsRead,
}) => (
  <Card
    key={message.id}
    style={{
      marginBottom: '8px',
      width: '100%',
      border: '1px solid #d9d9d9',
      borderRadius: '4px',
      fontSize: '12px',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    bodyStyle={{ margin: 0, padding: 10 }}
  >
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Tag
        color={message.read ? '#78b671' : '#FF0040'}
        style={{ borderRadius: '50%', padding: '2px', marginRight: '4px' }}
      />
      {message.name} <span style={{ color: '#FF0040', margin: '0 5px' }}>||</span>{' '}
      {moment(message.datetime.toDate()).format('MMM D, YYYY')}{' '}
      <span style={{ color: '#FF0040', margin: '0 5px' }}>||</span>{' '}
      {moment(message.datetime.toDate()).format('h:mm A')}
    </div>
    <div style={{ flex: '1', overflow: 'hidden', textOverflow: 'ellipsis', marginLeft: '8px' }}>
      {message.message.length > 10
        ? message.message.substring(0, 60) + '...'
        : message.message}
    {!message.read && (
      <Button
        type="text"
        icon={<CheckCircleOutlined />}
        onClick={markAsRead}
        style={{ padding: 0 }}
      />
    )}
    </div>
  </Card>
);

const MessageList: React.FC = () => {
  const { messages, updateReadStatus, fetchMessages } = useMessages();

  useEffect(() => {
    fetchMessages()
  },[])

  const handleMarkAsRead = async (id: string) => {
    await updateReadStatus(id, true);
  };

  return (
    <Tabs defaultActiveKey="unread" centered>
      <TabPane tab="Unread" key="unread">
        <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: '400px', overflowY: 'auto' }}>
          {messages
            .filter((message: Message) => !message.read)
            .map((message: Message) => (
              <MessageCard key={message.id} message={message} markAsRead={() => handleMarkAsRead(message.id)} />
            ))}
        </div>
      </TabPane>

      <TabPane tab="Read" key="read">
        <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: '400px', overflowY: 'auto' }}>
          {messages
            .filter((message: Message) => message.read)
            .map((message: Message) => (
              <MessageCard key={message.id} message={message} markAsRead={() => {}} />
            ))}
        </div>
      </TabPane>
    </Tabs>
  );
};

export default MessageList;
