import React, { useEffect, useState } from 'react';
import { Calendar, Badge, Tag } from 'antd';
import { db } from '../../firebaseConfig';
import { collection, query, getDocs, onSnapshot } from 'firebase/firestore';
import { useModal } from '../../context/modalContext';
import EventModal from './EventModal';

const CalendarComponent: React.FC = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const { openModal } = useModal();
  const [selectedEventId, setSelectedEventId] = useState<string | null>(null);

  useEffect(() => {
    const messagesQuery = query(collection(db, 'messages'));
  
    const unsubscribe = onSnapshot(messagesQuery, (snapshot) => {
      const updatedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(updatedMessages);
    }, (error) => {
      console.error('Error fetching messages:', error);
    });
  
    return () => unsubscribe();
  }, []);

  const fetchMessages = async () => {
    try {
      const messagesQuery = query(collection(db, 'messages'));
      const querySnapshot = await getDocs(messagesQuery);

      const messagesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMessages(messagesData);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const transformMessagesForCalendar = () => {
    const calendarData: { [key: string]: { content: React.ReactNode }[] } = {};

    messages.forEach((message) => {
      const date = new Date(message.datetime.toDate());
      const dateString = date.toISOString().split('T')[0];

      if (!calendarData[dateString]) {
        calendarData[dateString] = [];
      }

      const formattedTime = `${date.getHours()}:${date.getMinutes()}`;

      calendarData[dateString].push({
        content: (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              openModal(message.id);
              setSelectedEventId(message.id);
            }}
          >
            <Badge
              status={message.read ? 'success' : 'error'}
              text={
                <Tag
                  color={message.read ? '#78b671' : '#FF0040'}
                  style={{ color: '#fff' }}
                >
                  {message.name} | {formattedTime}
                </Tag>
              }
            />
          </div>
        ),
      });
    });

    return calendarData;
  };

  return (
    <>
      <Calendar
        mode="month"
        dateCellRender={(value) => {
          const formattedDate = value.toISOString().split('T')[0];
          const messagesForDate = transformMessagesForCalendar()[formattedDate];

          return (
            <div>
              {messagesForDate?.map((message, index) => (
                <div key={index}>{message.content}</div>
              ))}
            </div>
          );
        }}
      />
      {selectedEventId && <EventModal eventId={selectedEventId} fetchMessages={fetchMessages} />}
    </>
  );
};

export default CalendarComponent;
