import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from 'react-modal-video';
import { MdOutlineArrowForward, FiPlay, FiFacebook, FiInstagram, FiTwitter, FiLinkedin } from "../../assets/icons/vander";
import CountUp from 'react-countup';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

import Navbar from "../ux/navbar";
import Blog from "../ux/blog";
import Clients from "../ux/clients";
import GetInTuch from "../ux/getInTuch";
import Footer from "../ux/footer";
import ScrollTop from "../ux/scrollTop";

import { featuresData, accordionData, partnersImg, teamData, drveceData, navodData} from "../../data/data";

import backgroundImg from "../../assets/gallery1.jpg";
import about1 from "../../assets/gall4.jpg";
import about2 from "../../assets/gall5.jpg";
import about3 from "../../assets/gall3.jpg";
import bg2 from "../../assets/gall14.jpg";

import "../../../node_modules/react-modal-video/scss/modal-video.scss";

import { Link as Link1 } from 'react-scroll';

import logoLight from "../../assets/floring-logo.png";


interface FeatureData {
    id: string;
    iconName: string;
    icon: React.ElementType;
    title: string;
    desc: string;
  }
  
  interface AccordionData {
    id: string;
    title: string;
    desc: string;
  }
  
  interface TeamData {
    image: string;
    name: string;
    title: string;
    data: [teamDataValues]
  }

  type teamDataValues = {
    name: string;
    value: string;
  }

const IndexBusiness: React.FC = () => {
const [isOpen, setOpen] = useState(false);
const [activeIndex, setActiveIndex] = useState<string | number | null>(null);  

  return (
    <>
        <Navbar/>
        <section className="bg-home zoom-image d-flex align-items-center" id="home">
            <div className="bg-overlay image-wrap" id="hero-images" style={{backgroundImage:`url(${backgroundImg})`}}></div>
            <div className="bg-overlay bg-linear-gradient-2"></div>
            <div className="container">
                <div className="row justify-content-center mt-5">
                    <div className="col-12">
                        <div className="title-heading text-center">
                            <h4 className="display-5 text-white title-dark mb-4">Dobrodošli na  stranicu Floringa</h4>
                            <img src={logoLight} className="l-light" alt="" style={{height: 100, width: 'auto', objectFit: 'cover', marginBottom: "1.5rem"}}/>
                            <p
                            style={{
                                color: 'white',
                                fontSize: '1.5em', // You can adjust the font size accordingly
                                marginBottom: 0,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                            >Ivan Komadina +385919235665 i Mario Šarić +385916075787</p>
                            <br />
                            <p
                            style={{
                                color: 'white',
                                fontSize: '1.5em',
                                marginBottom: 0,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                            >floring.net@gmail.com</p>
                        
                            <div className="mt-4 pt-2">
                            <Link1 className="btn btn-primary-border-0 text-white" to="contact" activeClass="active" spy={true} smooth={true} duration={500} style={{background: 'linear-gradient(to bottom, #6dbb63, #70ba68, #74b86c, #78b671, #7cb476)'}}>Kontaktirajte nas</Link1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="position-relative">
            <div className="home-shape-arrow">
                <Link to="#about"><i className="mdi mdi-arrow-down arrow-icon text-dark h5 shadow-md"></i></Link>
            </div>
            
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>

        <section className="section" id="features">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary fw-normal">O nama</h6>
                            <h4 className="title mb-4">Obiteljska tvrtka koja se bavi <br/>hortikulturom više od 30 godina.</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Proizvodnja travnog busena, listopadnog drveća, hidrosjetva, uređivanje zelenih površina i ugradnja automatskih sustava za navodnjavanje.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {featuresData.map((item, index) => {
                        const Icon = item.icon;
                        return (
                            <div className="col-lg-4 col-sm-6 mt-4 pt-2 d-flex" key={index}>
                                <div className="card features feature-primary feature-clean feature-transition p-4 border-0 shadow rounded overflow-hidden flex-column h-100">
                                    <div className="icons text-center rounded">
                                        <img src={`./${item.iconName}`} className="icon d-block rounded h4 mb-0" alt={`${item.title} icon`} />
                                    </div>
                                    <div className="content mt-4">
                                        <h5 className="h5 text-dark title">{item.title}</h5>
                                        <p className="text-muted mt-3">{item.desc}</p>
                                    </div>

                                    <div className="feature-ovelay-one"></div>
                                    <div className="feature-ovelay-two"></div>
                                    <div className="feature-ovelay-three"></div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        </section>

        <section className="section bg-light" id="about">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="about-image position-relative">
                            <img src={about1} className="img-fluid rounded shadow" alt=""/>

                            <div className="about-image-position">
                                <img src={about2} className="img-fluid rounded shadow-lg" alt=""/>
                                <div className="play-icon">
                                    <Link to="#!" onClick={() => setOpen(true)} className="play-btn lightbox">
                                        <i className="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                                    </Link>
                                </div>
                                <ModalVideo
                                    channel="youtube"
                                    youtube={{ mute: 0, autoplay: 0 }}
                                    isOpen={isOpen}
                                    videoId="DKyv4xUybkI"
                                    onClose={() => setOpen(false)} 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title ms-lg-5">
                            <h6 className="text-primary fw-normal">Više Informacija</h6>
                            <h4 className="title mb-4"> Prednosti gotovih <br/> travnjaka</h4>
                            <p className="text-muted">Prednosti travnjaka zasnovanih polaganjem travnog busena su brojne. Travnjak je nakon desetak dana spreman za korištenje i unutar četiri tjedna za sva opterećenja. Odlikuje se gustoćom sklopa, tamno zelene je boje.</p>
                            <p className="text-muted mb-0">U jednom danu, na okućnici ili javnoj površini možete imati travnjak star godinu dana. 250 m2 travnog busena proizvodi dovoljno kisika za četveročlanu obitelj.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-3 col-6 d-flex justify-content-center">
                        <div className="counter-box position-relative text-center">
                            <h2 className="mb-0 display-2 fw-bold title-dark mt-2 opacity-1">+<CountUp start={0} end={30}/></h2>
                            <span className="counter-head fw-normal title-dark position-absolute top-50 start-0 end-0">Godina iskustva</span>
                        </div>
                    </div>

                    <div className="col-md-3 col-6 d-flex justify-content-center">
                        <div className="counter-box position-relative text-center">
                            <h2 className="mb-0 display-2 fw-bold title-dark mt-2 opacity-1"><CountUp start={0} end={1}/></h2>
                            <span className="counter-head fw-normal title-dark position-absolute top-50 start-0 end-0">Centralni ured</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row align-items-center">
                    <div className="col-md-6 order-1 order-md-2">
                        <div className="ms-lg-5">
                            <img src={about3} className="img-fluid rounded shadow" alt="" style={{minHeight: 520, height: "100%", objectFit: "cover"}}/>
                        </div>
                    </div>

                    <div className="col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="section-title">
                            <h6 className="text-primary fw-normal">Više Informacija</h6>
                            {/* <h4 className="title mb-4">Predanost prema  <br/> ljepoti prirode.</h4> */}
                            <div className="accordion mt-4" id="buyingquestion">
                                {accordionData.map((item, index) => {
                                return (
                                    <div className="accordion-item rounded shadow mt-2" key={index}>
                                    <h2 className="accordion-header">
                                        <button
                                        className={`${activeIndex === item.id ? "active accordion-button fw-normal border-0 bg-light rounded shadow" : "accordion-button fw-normal border-0 bg-light rounded shadow collapsed"}`}
                                        onClick={() => setActiveIndex(item.id)}
                                        >
                                        {item.title}
                                        </button>
                                    </h2>
                                    {activeIndex === item.id ?
                                        <div className="border-0">
                                        <div className="accordion-body text-muted bg-transparent">
                                            {item.desc}
                                        </div>
                                        </div> : null
                                    }
                                    </div>
                                );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="section" id="testimonial">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary fw-normal mb-2">Kalendar održavanja travnjaka</h6>
                            <h4 className="title mb-4">Učinkovito planiranje njege i održavanja<br/> vašeg travnjaka tijekom cijele godine</h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Ovaj detaljni kalendar pruža korisne smjernice za redovito održavanje vašeg travnjaka, uključujući pravilno zalijevanje, košnju, gnojidbu i druge važne savjete kako biste osigurali bujnu i zdravu travnatu površinu.Uz sve navedene mjere njege travnjaka neophodno je kvalitetno zalijevanje sa optimalnom količinom vode.</p>
                        </div>
                    </div>
                </div>
                <Clients/>
            </div>  

           <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary fw-normal mb-2">Travni busen</h6>
                            <h4 className="title mb-4">Travni busen za mediteranske zone i <br/> travni busen za sportske terene i javne površine </h4>
                            <p className="text-muted para-desc mb-0 mx-auto">Visokokvalitetni travni busen za mediteranske zone - Upoznajte čaroliju travnjaka za za sportske terene i javne površine.</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {teamData.map((item,index) => {
                        console.log(item)
                        return(
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2 mx-auto" key={index}>
                                <div className="card team team-primary team-two text-center">
                                    <div className="card-img team-image d-inline-block mx-auto rounded overflow-hidden" style={{minHeight: 480, height: '100%'}}>
                                        <img src={item.image} className="img-fluid" alt="" style={{minHeight: 480, height: '100%', objectFit: 'cover'}}/>
                                        <div className="card-overlay rounded"></div>

                                        <div className="content">
                                            <h5 className="text-white title-dark h5 mb-0 title">{item.name}</h5>
                                            <h6 className="text-white-50 mb-0 fw-normal mt-1 designation">{item.title}</h6>
                                            <table className="table mt-3">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Ime</th>
                                                        <th scope="col">Postotak</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {item.data.map((dataItem, dataIndex) => (
                                                        <tr key={dataIndex}>
                                                            <td>{dataItem.name}</td>
                                                            <td>{dataItem.value}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary fw-normal mb-2">Listopadno drveće</h6>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {drveceData.map((item,index) => {
                        console.log(item)
                        return(
                            <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2 mx-auto" key={index}>
                                <div className="card team team-primary team-two text-center">
                                    <div className="card-img team-image d-inline-block mx-auto rounded overflow-hidden" style={{minHeight: 480, height: '100%'}}>
                                        <img src={item.image} className="img-fluid" alt="" style={{minHeight: 480, height: '100%', objectFit: 'cover'}}/>
                                        <div className="card-overlay rounded"></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary fw-normal mb-2">Ugradnja automatskog sustava za navodnjavanje "Rain Bird"</h6>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {navodData.map((item,index) => {
                        console.log(item)
                        return(
                            <div className="col-lg-3 col-md-6 col-12 mt-4 pt-2 mx-auto" key={index}>
                                <div className="card team team-primary team-two text-center">
                                    <div className="card-img team-image d-inline-block mx-auto rounded overflow-hidden" style={{minHeight: 480, height: '100%'}}>
                                        <img src={item.image} className="img-fluid" alt="" style={{minHeight: 480, height: '100%', objectFit: 'cover'}}/>
                                        <div className="card-overlay rounded"></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

            {/* <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-4 pb-2">
                            <h6 className="text-primary fw-normal mb-2">Hidrosjetva</h6>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {teamData.map((item,index) => {
                        console.log(item)
                        return(
                            <div className="col-lg-4 col-md-6 col-12 mt-4 pt-2 mx-auto" key={index}>
                                <div className="card team team-primary team-two text-center">
                                    <div className="card-img team-image d-inline-block mx-auto rounded overflow-hidden" style={{minHeight: 480, height: '100%'}}>
                                        <img src={item.image} className="img-fluid" alt="" style={{minHeight: 480, height: '100%', objectFit: 'cover'}}/>
                                        <div className="card-overlay rounded"></div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div> */}
        </section>

        <section className="section "  style={{backgroundImage:`url(${bg2})`, backgroundAttachment:"fixed", backgroundRepeat:"no-repeat", backgroundSize:'cover'}} id="cta">
            <div className="bg-overlay"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title">
                            <h4 className="title title-dark text-white mb-4">Kvalitetni travnjaci i listopadno drveće</h4>
                            <p className="text-light para-dark para-desc mx-auto">Proizvodimo visokokvalitetni travni busen u Uglješu (Baranja) na površini od 15 hektara. Isporučujemo ga u trakama širine 40 cm i dužine 125 cm, s debljinom supstrata od 1,50-2,0 cm, smotanog u role. Pakiramo ga na palete dimenzije euro i na jednu paletu stane 25 m2 težine cca 750 kg (težina 1m2 travnog busena iznosi cca 30 kg, ovisno o stupnju vlažnosti). Isporučujemo ga na paritetu naši travnjaci Uglješ ili na odredišta kupca, u suradnji s našim kooperantima vozarima.</p>
                            <p className="text-light para-dark para-desc mx-auto">Osim toga, uzgajamo listopadno bjelogorično drveće prema visokim standardima kvalitete. Drveće uzgajamo u tlu, a sve sadnice su uzgojene po pravilima struke.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <GetInTuch/>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="card map border-0">
                        <div className="card-body p-0">
                          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10107.206384961047!2d18.6928030799328!3d45.55496202774924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4759827466d6b9d5%3A0xd1f6fc14ce3442b7!2sBosutska%209%2C%2031326%20Uglje%C5%A1!5e0!3m2!1sen!2sus!4v1566305861440!5m2!1sen!2sus
" title="Floring d.o.o" style={{border:"0"}} allowFullScreen loading="lazy"></iframe>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section className="section">
            <div className="container mt-5">
                <div className="row justify-content-center">
                    {partnersImg.map((item,index) => {
                        return(
                            <div className="col-lg-2 col-md-2 col-6 py-2" key={index}>
                                <div className="text-center">
                                    <img src={`./${item}`} className="avatar avatar-md-md w-auto" alt="" style={{height: 40, objectFit: 'cover'}}/>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>

        <Footer/>
        <ScrollTop/>
        </>
);
};

export default IndexBusiness;
