import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as Link1 } from 'react-scroll';
import logoDark from "../../assets/floring-logo.png";
import logoLight from "../../assets/floring-logo.png";

import {
  BiLogoFacebook,
  BiLogoInstagram,
  BiLogoTwitter,
  BiLogoLinkedinSquare,
  FiMail
} from "../../assets/icons/vander";

export default function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [isMenu, setisMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  const toggleMenu = () => {
    setisMenu(!isMenu);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation")?.getElementsByTagName("a") || []
      );
      anchorArray.forEach(element => {
        element.addEventListener('click', (elem) => {
          const target = (elem.target as HTMLElement)?.getAttribute("href"); // Type assertion
          if (target !== null && target !== undefined && target !== "") {
            if ((elem.target as HTMLElement)?.nextElementSibling) { // Type assertion
              var submenu = (elem.target as HTMLElement)?.nextElementSibling?.nextElementSibling;
              submenu?.classList.toggle('open'); // Use optional chaining
            }
          }
        });
      });
    }
  };

  return (
    <>
      <div className="tagline bg-white">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <ul className="list-unstyled mb-0">
                  <li className="list-inline-item mb-0">
                    <Link to="#" className="text-muted fw-normal">
                      <FiMail className="fea icon-sm text-primary" /> floring.net@gmail.com
                    </Link>
                  </li>
                </ul>

                <ul className="list-unstyled social-icon tagline-social mb-0">
                  <li className="list-inline-item mb-0">
                    <Link to="https://www.facebook.com/people/Floring-Osijek/61556277271757/?name=xhp_nt__fb__action__open_user">
                      <BiLogoFacebook className="mb-0 icon" />
                    </Link>
                  {/* </li>
                  <li className="list-inline-item mb-0">
                    <Link to="#">
                      <BiLogoInstagram className="mb-0 icon" />
                    </Link>
                  </li>
                  <li className="list-inline-item mb-0">
                    <Link to="#">
                      <BiLogoTwitter className="mb-0 icon" />
                    </Link>
                  </li>
                  <li className="list-inline-item mb-0">
                    <Link to="#">
                      <BiLogoLinkedinSquare className="mb-0 icon" />
                    </Link>*/}
                  </li> 
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header id="topnav" className={`${scroll ? "nav-sticky" : ""} defaultscroll sticky tagline-height`}>
        <div className="container">
          <Link className="logo" to="/">
            <span className="logo-light-mode">
              <img src={logoDark} className="l-dark" alt="" style={{height: 40, width: 130, objectFit: 'cover'}}/>
              <img src={logoLight} className="l-light" alt="" style={{height: 40, width: 130, objectFit: 'cover'}}/>
            </span>
            <img src={logoLight} className="logo-dark-mode" alt="" style={{height: 40, width: 130, objectFit: 'cover'}}/>
          </Link>
          <div className="menu-extras">
            <div className="menu-item">
              <Link
                to="#"
                className={`navbar-toggle ${isMenu ? 'open' : ''}`}
                id="isToggle"
                onClick={() => toggleMenu()}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
            </div>
          </div>

          <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
            <ul className="navigation-menu nav-light" id="navmenu-nav">
              <li className="has-submenu">
                <Link1 to="home" activeClass="active" spy={true} smooth={true} duration={500} className="sub-menu-item">
                  Početna
                </Link1>
              </li>
              <li className="has-submenu">
                <Link1 to="features" activeClass="active" spy={true} smooth={true} duration={500} className="sub-menu-item">
                  O nama
                </Link1>
              </li>
              <li className="has-submenu">
                <Link1 to="about" activeClass="active" spy={true} smooth={true} duration={500} className="sub-menu-item">
                  Više informacija
                </Link1>
              </li>
              <li className="has-submenu">
                <Link1 to="testimonial" activeClass="active" spy={true} smooth={true} duration={500} className="sub-menu-item">
                  Proizvodnja
                </Link1>
              </li>
              <li className="has-submenu">
                <Link1 to="contact" activeClass="active" spy={true} smooth={true} duration={500} className="sub-menu-item">
                  Kontakt
                </Link1>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}
