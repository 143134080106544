import React, { useEffect, useState } from 'react';
import { Modal, Card, Button, Spin } from 'antd';
import { useModal } from '../../context/modalContext';
import { useMessages } from '../../context/messagesContext';
import { db } from '../../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

interface EventModalProps {
  eventId: string;
  fetchMessages: () => Promise<void>;
}

const EventModal: React.FC<EventModalProps> = ({ eventId, fetchMessages }) => {
  const { isModalVisible, closeModal } = useModal();
  const { updateReadStatus } = useMessages();
  const [eventData, setEventData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true);
        const eventDocRef = doc(db, 'messages', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          setEventData(eventDocSnap.data());
        } else {
          console.log('No such document!');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching event data:', error);
        setLoading(false);
      }
    };

    if (isModalVisible && eventId) {
      fetchEventData();
    }
  }, [isModalVisible, eventId]);

  const handleCancel = async () => {
    closeModal();
    await fetchMessages();
  };

  const handleUpdateReadStatus = async () => {
    if (eventId) {
      await updateReadStatus(eventId, true);
      await fetchMessages();
    }
  };

  return (
    <Modal
      title="Event Details"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="updateRead" onClick={handleUpdateReadStatus}>
          Update Read Status
        </Button>,
        <Button key="close" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      {loading ? (
        <Spin />
      ) : (
        <Card>
          {eventData && (
            <>
              <p>Name: {eventData.name}</p>
              <p>Phone: {eventData.phone}</p>
              <p>Email: {eventData.email}</p>
              <p>Message: {eventData.message}</p>
            </>
          )}
        </Card>
      )}
    </Modal>
  );
};

export default EventModal;
