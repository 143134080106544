import React, { useState } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { db } from '../../firebaseConfig'; // Firebase config
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';

const BarCodeComponent = () => {
  const [form] = Form.useForm();
  const [barcodeUrl, setBarcodeUrl] = useState('');
  const [baka, ovoJeBaka] = useState('')
  const [loading, setLoading] = useState(false);

  const saveBarcodeData = async (values: any, imageData: any) => {
    setLoading(true)
    try {
      // Prepare the data to save
      const saveData = {
        ...values,
        amount: `${baka} €` ,
        barcodeImageRef: "" // Initialize barcode image reference
      };

      // Save data to Firestore
      const docRef = await addDoc(collection(db, "racuni"), saveData);

      // Upload image to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage, `racuni/${docRef.id}/barcode.jpg`);
      await uploadString(storageRef, imageData, 'data_url');

      // Get download URL for the uploaded image
      const downloadURL = await getDownloadURL(storageRef);
      
      // Update document with image URL
      await updateDoc(doc(db, "racuni", docRef.id), {
        barcodeImageRef: downloadURL,
        id: docRef.id, // Store the document ID inside the document itself
        isPayed: false
      });

    } catch (error) {
      setLoading(false)
      console.error("Error saving data or uploading image: ", error);
    } finally {
      setLoading(false)
    }
  };

  const normalizeAndValidateAmount = (amount: any) => {
    if (!/^\d+(\.\d{1,2})?$/.test(amount)) {
      alert("Please enter a valid amount with up to two decimal places.");
      return null; // Return null if the amount is invalid
    }
    return Math.round(parseFloat(amount) * 100).toString(); // Convert to cents
  };

  const generateBarcode = async (values: any) => {
    setLoading(true)
    const baseUrl = "https://us-central1-floring-8f2d4.cloudfunctions.net/app";
    values.amount = normalizeAndValidateAmount(values.amount);
    if (!values.amount) return; // Stop if the amount is invalid
  
    const queryParams = new URLSearchParams({
      id: values.id,
      header: values.header,
      currency: values.currency,
      amount: values.amount, // Use normalized amount
      payerName: values.payerName,
      payerAddress: values.payerAddress,
      payerCity: values.payerCity,
      recipientName: values.recipientName,
      recipientAddress: values.recipientAddress,
      recipientCity: values.recipientCity,
      iban: values.iban,
      model: values.model,
      referenceNumber: values.referenceNumber,
      purposeCode: values.purposeCode,
      description: values.description,
      isPayed: values.isPayed
    }).toString();
  
    const fullUrl = `${baseUrl}/generate-barcode?${queryParams}`;
  
    try {
      const response = await fetch(fullUrl, { mode: 'cors' });
      const data = await response.text();
      if (response.ok) {
        setBarcodeUrl(data);
        saveBarcodeData(values, data);
      } else {
        throw new Error('Error generating barcode');
      }
    } catch (error) {
      console.error('Network or generation error', error);
      setLoading(false)
    } finally {
      setLoading(false)
    }
  };

  const onFinish = (values: any) => {
    generateBarcode(values);
  };
  
  return (
    <Card title="Generisanje PDF417 Bar Koda">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item name="header" label="Header" initialValue="HRVHUB30">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="currency" label="Currency" initialValue="EUR">
          <Input readOnly />
        </Form.Item>
        <Form.Item name="amount" label="Amount (in euros)" rules={[{ required: true }]}>
          <Input placeholder="Unesite iznos" onChange={(e: any) => ovoJeBaka(e.target.value)}/>
        </Form.Item>
        <Form.Item name="payerName" label="Payer Name" rules={[{ required: true }]}>
          <Input placeholder="Unesite ime platitelja ili naziv tvrtke" />
        </Form.Item>
        <Form.Item name="payerAddress" label="Payer Address" rules={[{ required: true }]}>
          <Input placeholder="Unesite adresu platitelja" />
        </Form.Item>
        <Form.Item name="payerCity" label="Payer City" rules={[{ required: true }]}>
          <Input placeholder="Unesite poštanski broj i grad platitelja" />
        </Form.Item>
        <Form.Item name="recipientName" label="Recipient Name" rules={[{ required: true }]}>
          <Input placeholder="Unesite ime primatelja ili naziv tvrtke" />
        </Form.Item>
        <Form.Item name="recipientAddress" label="Recipient Address" rules={[{ required: true }]}>
          <Input placeholder="Unesite adresu primatelja" />
        </Form.Item>
        <Form.Item name="recipientCity" label="Recipient City" rules={[{ required: true }]}>
          <Input placeholder="Unesite poštanski broj i grad primatelja" />
        </Form.Item>
        <Form.Item name="iban" label="IBAN / Account Number" rules={[{ required: true }]}>
          <Input placeholder="Unesite IBAN ili broj računa primatelja" />
        </Form.Item>
        <Form.Item name="model" label="Model" rules={[{ required: true }]}>
          <Input placeholder="Unesite model za plaćanje" />
        </Form.Item>
        <Form.Item name="referenceNumber" label="Reference Number" rules={[{ required: true }]}>
          <Input placeholder="Unesite referencu broj" />
        </Form.Item>
        <Form.Item name="purposeCode" label="Purpose Code" rules={[{ required: true }]}>
          <Input placeholder="Unesite šifru namjene" />
        </Form.Item>
        <Form.Item name="description" label="Description" rules={[{ required: true }]}>
          <Input.TextArea placeholder="Unesite opis plaćanja" />
        </Form.Item>
        <Button type="primary" htmlType="submit" disabled={loading} loading={loading}>Generiraj Bar Kod</Button>
      </Form>
      {barcodeUrl && (
        <div style={{ marginTop: '20px' }}>
        <img src={barcodeUrl} alt="Generated Barcode" style={{ maxWidth: '100%' }} />
      </div>
    )}
  </Card>
);
};

export default BarCodeComponent;