// src/components/views/Public.tsx
import React from 'react';
import IndexBusiness from './index-business';

const Public: React.FC = () => {
  return (
    <IndexBusiness />
  );
};

export default Public;
